<template>
  <v-app-bar scroll-behavior="elevate" scroll-threshold="50" color="primary-darken-3" class="py-3 pe-4">
    <v-app-bar-title>
      <div class="d-flex align-center">
        <router-link to="/"
          ><img width="180" src="@/assets/logo-trammo-vessel-tracker.png" alt="Trammo Vessel Tracker"
        /></router-link>
      </div>
    </v-app-bar-title>

    <TimeClocks class="ml-auto mr-1" />
    <template v-slot:append>
      <v-btn size="large" @click="$router.push('/')" icon="fa-regular fa-home"></v-btn>
      <v-btn size="large" @click="onLogout()" icon="fa-regular fa-power-off"></v-btn>
    </template>
  </v-app-bar>
</template>

<script setup>
import { useAuthStore } from '@/stores/auth'

const authStore = useAuthStore()

function onLogout() {
  authStore.logout()
}
</script>
