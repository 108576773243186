/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import vuetify from './vuetify'
import pinia from '@/stores'
import router from '@/router'
import dayjs from './dayjs.js'
import globalDateFormats from './globalDateFormats.js'
import VCalendar from './vcalendar.js'
import { axios, VueAxios } from './axios.js'
import VueNumerals from 'vue-numerals'

export function registerPlugins(app) {
  // register dayjs globally as $date
  app.config.globalProperties.$date = dayjs
  app.config.globalProperties.$dateFormats = globalDateFormats
  // register plugins
  app.use(vuetify).use(router).use(pinia).use(VueAxios, axios).use(VueNumerals)
  app.provide('$axios', app.config.globalProperties.axios)
  app.provide('$date', app.config.globalProperties.$date)
  app.provide('$dateFormats', app.config.globalProperties.$dateFormats)
  app.use(VCalendar, {
    componentPrefix: 'C',
    isDark: false,
  })
}
