// font-awesome
import { aliases, fa } from 'vuetify/iconsets/fa'

const lightTheme = {
  dark: false,
  colors: {
    background: '#080B3C',
    surface: '#f2f2f2',
    'surface-bright': '#3F4276',
    'surface-medium': '#e7e9ea',
    'surface-light': '#F5F5F5',
    'surface-lighter': '#fafafa',
    'surface-lightest': '#FEFEFE',
    'surface-variant': '#DFE2E5',
    'on-surface': '#444444',
    'on-surface-variant': '#080B3C',
    primary: '#02446F',
    secondary: '#798892',
    anchor: '#059AFA',
    accent: '#171796',
    error: '#FA434F',
    info: '#059AFA',
    success: '#00B498',
    warning: '#ffc857',
  },
  variables: {
    'border-color': '#798892',
    'border-color-light': '#aebdc8',
    'border-opacity': 0.12,
    'high-emphasis-opacity': 0.87,
    'medium-emphasis-opacity': 0.6,
    'disabled-opacity': 0.38,
    'idle-opacity': 0.04,
    'hover-opacity': 0.04,
    'focus-opacity': 0.12,
    'selected-opacity': 0.08,
    'activated-opacity': 0.12,
    'pressed-opacity': 0.12,
    'dragged-opacity': 0.08,
    'theme-background': 'transparent',
    'theme-kbd': '#212529',
    'theme-on-kbd': '#FFFFFF',
    'theme-code': '#F5F5F5',
    'theme-on-code': '#333333',
  },
}

export const trammoLight = {
  defaults: {
    global: {
      ripple: false,
      density: 'compact',
      rounded: 'md',
    },
    VAvatar: {
      rounded: 'circle',
    },
    VBanner: {
      color: 'primary',
    },
    VBtn: {
      color: 'info',
      density: 'default',
      elevation: 0,
    },
    VTextField: {
      variant: 'solo',
      hideDetails: true,
    },
    VTextarea: {
      variant: 'solo',
    },
    VCheckbox: {
      color: 'secondary',
    },
    VAutocomplete: {
      variant: 'solo',
    },
    VCombobox: {
      variant: 'solo',
    },
    VSelect: {
      variant: 'solo',
      hideDetails: true,
    },
    VNumberInput: {
      variant: 'solo',
      controlVariant: 'stacked',
      VBtn: {
        color: 'thirdary',
      },
      hideDetails: true,
    },
    VAutocomplete: {
      variant: 'solo',
      hideDetails: true,
      VTextField: {
        variant: 'solo',
      },
    },
    VFileInput: {
      variant: 'solo',
      hideDetails: true,
    },
    VSlider: {
      color: 'info',
    },
    VTabs: {
      color: 'info',
    },
    VToolbar: {
      VBtn: {
        color: null,
      },
    },
    VIcon: {
      size: 'small',
    },
    VSwitch: {
      color: 'info',
      hideDetails: true,
    },
    VAlert: {
      VBtn: {
        color: null,
      },
    },
    vFileInput: {
      hideDetails: true,
    },
  },
  theme: {
    defaultTheme: 'lightTheme',
    variations: {
      colors: ['primary', 'secondary', 'accent', 'info', 'error', 'warning', 'success', 'grey'],
      lighten: 4,
      darken: 4,
    },
    themes: {
      lightTheme,
    },
  },

  icons: {
    defaultSet: 'fa',
    aliases,
    sets: {
      fa,
    },
  },
}
