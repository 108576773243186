<script setup lang="ts">
import { useRegisterSW } from 'virtual:pwa-register/vue'

const { needRefresh, updateServiceWorker } = useRegisterSW()

async function close() {
  // offlineReady.value = false
  needRefresh.value = false
}
</script>

<template>
  <Transition>
    <div v-if="needRefresh" class="pwa-overlay">
      <div class="pwa-toast" role="alert">
        <h4 class="bg-info-lighten-2 py-3 mb-0 text-info-darken-3 mb-4">
          <i class="far fa-refresh mr-2" />App update available
        </h4>
        <div class="message py-3">
          <!-- <span v-if="offlineReady"> App ready to work offline </span> -->
          <p class="text-center text-body-1 mb-3">
            A new version of the application is available, please clear the browser's cache and reload .
          </p>
          <p class="text-center mb-4 text-body-2">
            <a target="blank" class="text-reset" href="https://support.google.com/accounts/answer/32050?hl=en">
              How do I clear my cache in Chrome ?
            </a>
          </p>
        </div>
        <v-btn @click="updateServiceWorker()">Update</v-btn>
      </div>
    </div>
  </Transition>
</template>

<style>
.pwa-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(var(--v-theme-surface-bright), 0.9);
}
.pwa-toast {
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  margin: 0 0 40px;
  padding: 12px;
  border-radius: 4px;
  z-index: 1;
  box-shadow: 10px 10px 20px -10px rgba(0, 0, 0, 0.45);
  background-color: rgb(var(--v-theme-surface));
  text-align: center;
  min-width: 320px;
  max-width: 480px;
}
.pwa-toast .message {
  padding: 20px;
}
.pwa-toast button {
  border: 1px solid #8885;
  outline: none;
  margin-right: 5px;
  border-radius: 2px;
  padding: 3px 10px;
}
</style>
