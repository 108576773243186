<template>
  <div class="toaster">
    <TransitionGroup name="notifications" tag="div">
      <div class="mb-3" v-for="toast in toasts" :key="toast.id" :class="`toast bg-${toast.type}`">
        <h4 v-if="toast.title">{{ toast.title }}</h4>
        <div class="toast-content">
          {{ toast.message }}
          <button title="close" @click="notificationStore.closeNotification(toast.id)">
            <i class="far fa-times" />
          </button>
        </div>
      </div>
    </TransitionGroup>
  </div>
</template>

<script setup>
import { useNotificationStore } from '@/stores/notification'

const notificationStore = useNotificationStore()
const toasts = computed(() => notificationStore.toasts)
</script>

<style lang="scss" scoped>
.toaster {
  position: fixed;
  top: 90px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 9999;
  .toast {
    padding: 10px 20px;
    border-radius: 4px;
    width: 280px;
    font-size: 0.9rem;
    .toast-content {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    color: rbg(var(--v-theme-on-background));
    box-shadow: 0 8px 20px -8px rgba(0, 0, 0, 0.6);
    transition: all 0.5s ease;
    button {
      background: none;
      border: none;
      color: white;
      margin-left: 10px;
      cursor: pointer;
    }
  }
  /* transitions */
  .notifications-enter-active,
  .notifications-leave-active {
    transition: all 0.5s ease;
  }
  .notifications-enter-from,
  .notifications-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }
}
</style>
