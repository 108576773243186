const date = new Date()
export default {
  dayNoZero: 'D',
  day: 'DD',
  year: 'YYYY',
  dayMonth: 'DD/MM',
  dayMonthYear: 'DD/MM/YYYY',
  shortDayMonthYear: 'DD/MM/YY',
  hoursMinutes: 'HH:mm',
  date: 'D MMMM YYYY',
  dateShortMonth: 'D MMM YYYY',
  dateShort: 'D MMMM',
  fullDate: 'dddd, MMMM D YYYY',
  mediumDate: 'MMMM D, YYYY',
  dayMonthHourMin: 'DD/MM - H:mm',
  fullShort: 'DD/MM/YYYY - H:mm',
  full: 'dddd, DD/MM/YYYY - H:mm',
  fullShortTz: 'DD/MM/YYYY - H:mm UTCZ',
  fullTz: 'dddd, DD/MM/YYYY - H:mm UTCZ',
  DHLFormat: 'H:mm - DD/MM/YYYY',
  defaultTz: Intl.DateTimeFormat().resolvedOptions().timeZone,
  defaultGtm: date.getTimezoneOffset() / -60,
  outputFormat: 'YYYY-MM-DDTHH:mm:ssZ',
}
