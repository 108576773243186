<template>
  <div class="time-clock text-center">
    <div class="time text-secondary">{{ localTime }}</div>
    <div class="city">{{ clock.city }}</div>
  </div>
</template>

<script>
export default {
  name: 'TimeClock',
  props: {
    clock: Object,
    dateNow: Object,
  },
  computed: {
    localTime() {
      return this.$date(this.dateNow).tz(this.clock.tz).format(this.$dateFormats.hoursMinutes)
    },
  },
}
</script>

<style lang="scss" scoped>
.time-clock {
  background-color: rgba(var(--v-theme-surface), 5%);
  border: 1px solid rgba(var(--v-theme-surface), 24%);
  border-radius: 8px;
  line-height: 30px;
  min-width: 100px;
  padding: 2px 4px 3px;
  position: relative;
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 45%;
    background: linear-gradient(rgba(var(--v-theme-surface), 0.1), rgba(var(--v-theme-surface), 0.15));
  }
  .city {
    font-size: 11px;
    line-height: 10px;
  }
  .time {
    font-size: 30px;
    line-height: 30px;
    font-weight: 900;
  }
}
</style>
