import axios from 'axios'
import VueAxios from 'vue-axios'
import appConfigs from '../appConfig'
import router from '../router'

import { useAuthStore } from '@/stores/auth'
import { useNotificationStore } from '@/stores/notification'

axios.defaults.baseURL = appConfigs.apiUrl
axios.defaults.withCredentials = false

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response ? error.response.status : null
    // handle error
    const notification = useNotificationStore()
    const auth = useAuthStore()

    if (status === 401) {
      // Handle unauthorized access
      notification.newNotification({
        title: 'Unauthorized',
        message: 'Your session expired. Please login again.',
        type: 'error',
      })
    } else if (status === 404) {
      // Handle not found errors
      notification.newNotification({
        title: 'Not found',
        message: 'The requested resource was not found.',
        type: 'error',
      })
    } else {
      // Handle other errors
      notification.newNotification({
        title: 'Backend API unavailable',
        message: 'Connection lost with server.',
        type: 'error',
      })
    }
    // redirect to login page when user is not logged in or server is not available
    if ((!status || status === 401) && router.currentRoute.fullPath !== '/login') {
      setTimeout(() => {
        auth.clear()
        router.push({ name: 'login' })
      }, 800)
    }
    return Promise.reject(error)
  },
)

export { axios, VueAxios }
