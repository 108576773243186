import { DefaultLayout, AuthLayout } from '@/layouts/index'

export const routes = [
  {
    path: `/login`,
    name: 'login',
    component: () => import('@/pages/Login.vue'),
    meta: { title: 'Sign In', layout: AuthLayout },
  },
  {
    path: `/`,
    name: 'dashboard',
    component: () => import('@/pages/Dashboard.vue'),
    meta: { title: 'Welcome', requiresAuth: true, layout: DefaultLayout },
  },
  {
    path: `/components`,
    name: 'components',
    component: () => import('@/pages/Components.vue'),
    meta: { title: 'Welcome', requiresAuth: true, layout: DefaultLayout },
  },
  {
    path: `/reports-history`,
    redirect: `/reports-history/noon-reports`,
  },
  {
    path: `/reports-history/noon-reports`,
    name: 'noon-reports-history',
    component: () => import('@/pages/NoonReportHistory.vue'),
    meta: { title: 'Noon Reports', requiresAuth: true, layout: DefaultLayout },
  },
  {
    path: `/reports-history/statements-of-facts`,
    name: 'statements-of-facts-history',
    component: () => import('@/pages/StatementOfFactsHistory.vue'),
    meta: { title: 'Statements of Facts', requiresAuth: true, layout: DefaultLayout },
  },
  {
    path: `/noon-reports/:id`,
    name: 'noon-form',
    props: true,
    component: () => import('@/pages/NoonReportForm.vue'),
    meta: { title: 'Edit a Noon Report', requiresAuth: true, layout: DefaultLayout },
  },
  {
    path: `/statements-of-facts/:id`,
    name: 'statement-form',
    props: true,
    component: () => import('@/pages/StatementOfFactsForm.vue'),
    meta: { title: 'Edit a Statement of Facts', requiresAuth: true, layout: DefaultLayout },
  },
  {
    path: '/components',
    name: 'components',
    component: () => import('@/pages/Components.vue'),
    meta: { title: 'Components', requiresAuth: true, layout: DefaultLayout },
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: () => import('@/pages/NotAuthorized.vue'),
    meta: { title: 'Unauthorized', requiresAuth: true, layout: DefaultLayout },
  },
  {
    name: 'NotFound',
    path: '/:pathMatch(.*)*',
    component: () => import('@/pages/NotFound.vue'),
    meta: { title: 'Not Found', layout: DefaultLayout },
  },
]
