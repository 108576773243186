import { defineStore } from 'pinia'
import { axios } from '@/plugins/axios'
import { useAppStore } from './app'

export const useAuthStore = defineStore('auth', {
  // convert to a function
  state: () => ({
    user: null,
  }),

  getters: {
    loggedIn: (state) => !!state.user,
    userFullName: (state) => (state.user ? `${state.user.firstName} ${state.user.lastName}` : ''),
    isSuperAdmin: (state) => {
      return state.user?.role === 0 || false
    },
  },

  actions: {
    async setUserData(userData) {
      this.user = userData

      if (this.user && this.user.token) {
        // populate user's token
        axios.defaults.headers.common['Authorization'] = `Bearer ${userData.token}`
      } else {
        axios.defaults.headers.common['Authorization'] = ''
      }
      return this.user
    },

    async register(credentials) {
      const response = await axios.post('/register', {
        ...credentials,
      })
      await this.setUserData(response.data)
    },

    async login(credentials) {
      try {
        const response = await axios.post('/login', {
          ...credentials,
        })
        const user = await this.setUserData(response.data)
        if (user.token) {
          this.router.push({ name: 'dashboard' })
        }
      } catch (e) {
        console.error(e)
        throw e
      }
    },

    async logout() {
      const app = useAppStore()

      try {
        await axios.post('/logout', {
          email: this.user.email,
          session: this.user.session,
          token: this.user.token,
        })
      } catch (e) {
        console.error(e)
        throw e
      }
      app.vesselName = '-'
      await this.setUserData(null)
      if (this.router.currentRoute.path !== '/login') {
        this.router.push({ name: 'login' })
      }
    },
    async emptySession() {
      await this.setUserData(null)
    },

    async restoreState() {
      await this.setUserData(this.user)
    },

    // easily reset state using `$reset`
    clear() {
      this.$reset()
      if (this.router.currentRoute.path !== '/login') {
        this.router.push({ name: 'login' })
      }
    },
  },
  persist: true,
})
