import { defineStore } from 'pinia'
import { ref } from 'vue'

type ToastType = 'success' | 'error' | 'info'
interface Toast {
  id: number
  title?: string
  message: string
  type: ToastType
}
interface Notification {
  title?: string
  message: string
  type: ToastType
}

export const useNotificationStore = defineStore('notification', () => {
  const toasts = ref<Toast[]>([])

  let idCounter = 0
  const newNotification = (notification: Notification) => {
    const id = idCounter++
    const m = notification.message || ''
    const message = m.includes('400')
      ? m.replace('Request failed with status code 400', 'Bad request / missing data.')
      : m.includes('401')
        ? m.replace('Request failed with status code 401', 'Session expired')
        : m.includes('403')
          ? m.replace('Request failed with status code 403', 'Unauthorized')
          : m.includes('404')
            ? m.replace('Request failed with status code 404', 'Network error, service unavailable.')
            : m.includes('500')
              ? 'Serveur error, service unavailable.'
              : m
    toasts.value.push({ id, title: notification.title, message: m, type: notification.type })
    setTimeout(() => closeNotification(id), 4000) // Auto-remove after 3 seconds
  }
  const closeNotification = (id: number) => {
    toasts.value = toasts.value.filter((toast) => toast.id !== id)
  }
  return { toasts, newNotification, closeNotification }
})
