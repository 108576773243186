/**
 * router/index.ts
 */
// Composables
import { createRouter, createWebHistory } from 'vue-router/auto'
// import { setupLayouts } from 'virtual:generated-layouts'
// import { routes } from 'vue-router/auto-routes'
import { routes } from '@/router/routes'
import { useAuthStore } from '@/stores/auth'
import { axios } from '@/plugins/axios'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  // routes: setupLayouts(routes),
  routes,
})

// Workaround for https://github.com/vitejs/vite/issues/11804
router.onError((err, to, next) => {
  if (err?.message?.includes?.('Failed to fetch dynamically imported module')) {
    if (!localStorage.getItem('vuetify:dynamic-reload')) {
      console.log('Reloading page to fix dynamic import error')
      localStorage.setItem('vuetify:dynamic-reload', 'true')
      location.assign(to.fullPath)
    } else {
      console.error('Dynamic import error, reloading page did not fix it', err)
    }
  } else {
    console.error(err)
    next('/login')
  }
})

router.isReady().then(() => {
  localStorage.removeItem('vuetify:dynamic-reload')
})

// auth controller
router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore()
  await authStore.restoreState()

  // console.log('authStore.loggedIn', authStore.loggedIn)

  if (to.matched.some((record) => record.meta.requiresAuth === true)) {
    // If the user is not authenticated, redirect to login
    if (!authStore.loggedIn) {
      next('/login')
    } else {
      //add axios headers based on route
      addStatHeader(to)
      next()
    }
  } else {
    // console.log('to.name', to.name)
    // If the user is authenticated, redirect to dashboard
    if ((to.name = 'login' && authStore.loggedIn)) {
      addStatHeader(to)
      next('/')
    } else {
      next()
    }
  }
})

const addStatHeader = (to) => {
  const pathSplitted = to.fullPath.split('/')
  const paramId = parseInt(pathSplitted.at(-1))
  axios.defaults.headers.common['X-Resource'] = to.meta.resource || ''
  axios.defaults.headers.common['X-Origin'] = to.fullPath || ''
  axios.defaults.headers.common['X-Identifier'] = paramId && !isNaN(paramId) ? paramId : ''
}

export default router
