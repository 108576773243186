<template>
  <div class="d-none d-sm-flex align-center timer-clocks">
    <time-clock v-for="clock in clocks" :key="clock.city" :clock="clock" :dateNow="dateNow" class="clock mr-2" />
  </div>
</template>

<script>
import TimeClock from './TimeClock.vue'

export default {
  name: 'TimeClocks',
  components: {
    TimeClock,
  },
  data() {
    return {
      timerId: null,
      dateNow: null,
      clocks: [
        {
          tz: 'America/New_York',
          city: 'New York',
        },
        {
          tz: 'Europe/Paris',
          city: 'Paris',
        },
        {
          tz: 'Asia/Dubai',
          city: 'Dubai',
        },
        {
          tz: 'Asia/Calcutta',
          city: 'New Delhi',
        },
        {
          tz: 'Asia/Shanghai',
          city: 'Shanghai',
        },
      ],
    }
  },
  created() {
    this.getTime()
    this.updateTime()
  },
  destroyed() {
    this.clearTime()
  },
  methods: {
    getTime() {
      this.dateNow = this.$date()
    },
    updateTime() {
      //refresh api every 5 seconds
      this.timerId = setInterval(() => this.getTime(), 20000)
    },
    clearTime() {
      clearInterval(this.timerId)
    },
  },
}
</script>

<style lang="scss" scoped>
.timer-clocks {
  @media (max-width: 1024px) {
    .clock {
      &:last-child {
        display: none;
      }
    }
  }
  @media (max-width: 960px) {
    .clock {
      &:nth-last-child(2) {
        display: none;
      }
    }
  }
  @media (max-width: 768px) {
    .clock {
      &:nth-last-child(3) {
        display: none;
      }
    }
  }
}
</style>
