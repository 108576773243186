/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Custom Styles
import '@/assets/scss/app.scss'
// vuetify styles
import '@/styles/main.scss'

import { trammoLight } from './blueprints/trammo.js'
import { createVuetify } from 'vuetify'
// import { VDateInput } from 'vuetify/labs/VDateInput'
import { VNumberInput } from 'vuetify/labs/VNumberInput'
// dayjs adapter
import DayJsAdapter from '@date-io/dayjs'

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  display: {
    mobileBreakpoint: 'md',
    thresholds: {
      sm: 600,
      md: 980,
      lg: 1170,
      xl: 1440,
      xxl: 1960,
    },
  },
  blueprint: trammoLight,
  locale: {
    locale: 'en',
  },
  components: {
    // VDateInput,
    VNumberInput,
  },
  date: {
    adapter: DayJsAdapter,
    locale: {
      en: 'en-US',
    },
  },
})
